import { INITIAL_RETURN_ORDER_SEARCH_PARAMS } from 'src/views/return/partials/components/Constants'
import { RETURN_ORDER_SEARCH_ADD } from '../actions/actionType'

const initialState = {
  fields: [],
  returnOrderSearchParams: INITIAL_RETURN_ORDER_SEARCH_PARAMS,
}
export default function ReturnReducer(state = initialState, action) {
  switch (action.type) {
    case RETURN_ORDER_SEARCH_ADD:
      return { ...state, returnOrderSearchParams: action.payload }
    default:
      return state
  }
}
